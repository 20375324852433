<template>
	<div>
		<h1 v-if="title">{{ title }}</h1>
		<div class="d-flex justify-content-start align-items-center buttons-wrapper">
			<b-button v-if="url" variant="primary" @click="saveImage()" :disabled="!uploadedImage || ($wToast && $wToast.isLoading())">Uložiť</b-button>
			<label>
				<input type="file" @change="uploadImage($event)" accept="image/*" ref="imageInput">
				<b-button variant="outline-primary" @click="$refs.imageInput.click()">Nahrať fotku</b-button>
			</label>
		</div>
		<img v-if="image || imagePreview" :src="imagePreview || image" class="image-preview">
		<p v-else>{{ noPhotoText }}</p>
	</div>
</template>

<script>
import wAxios from '@/plugins/w/axios'

export default {
	props: {
		title: {
			type: String,
			required: false
		},
		image: {
			type: String,
			required: false
		},
		url: {
			type: String,
			required: false
		},
		noPhotoText: {
			type: String,
			required: false,
			default: 'Nebola nahraná žiadna fotka'
		}
	},
	data() {
		return {
			uploadedImage: null,
			imagePreview: null
		}
	},
	methods: {
		async uploadImage() {
			this.uploadedImage = event.target.files[0]
			const reader = new FileReader()

			reader.onloadend = () => {
				this.imagePreview = reader.result
			}

			reader.readAsDataURL(this.uploadedImage)
			this.$emit('imageUploaded', this.uploadedImage)
		},
		async saveImage() {
			if (!this.uploadedImage) return

			const data = new FormData()
			data.append('avatar', this.uploadedImage)

			if (this.$wToast) this.$wToast.clear_loading()

			try {
				await wAxios.post_auth(this.url, data)
				this.$wToast ? this.$wToast.success() : console.error('Image uploaded')
			} catch (error) {
				this.$wToast ? this.$wToast.error(error) : console.error(error)
			}
		}
	}
}
</script>
<style lang="scss" scoped>
    h1 {
        margin-bottom: 25px;
    }

    .buttons-wrapper {
        margin-bottom: 20px;

        .btn {
            margin: 0 20px 10px 0;
        }
        
        label {
            margin-bottom: 0px;
        }

        input[type="file"] {
            display: none;
        }
    }

    .image-preview {
        max-height: 250px;
    }
</style>
